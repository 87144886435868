<template>
  <div class="card card-custom">
    <div class="card-body">
      <GeneralConditions />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import GeneralConditions from "@/view/pages/static/GeneralConditions";
export default {
  components: {
    GeneralConditions
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("General Conditions") }]);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}
</script>